import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const WordsPage = ({ data }) => (
  <Layout title="wise words">
    <section
      css={css`
        width: 100%;
        @media (min-width: 768px) {
          width: 60%;
        }
      `}
    >
      {data.words.nodes.map((word) => (
        <div
          id={word.id}
          key={word.id}
          css={css`
            margin-bottom: 20px;
          `}
        >
          {word.content}
        </div>
      ))}
    </section>
  </Layout>
);

export const query = graphql`
  query WordsPageQuery {
    words: allSanityWord {
      nodes {
        content
        date
        id
      }
    }
  }
`;

export default WordsPage;
